<template>
  <div class="">
    <b-badge :variant="onlineState" :id="`online-state-${device.id}`">{{
      device.online ? "online" : "offline"
    }}</b-badge>

    <b-tooltip v-if="!device.online" :target="`online-state-${device.id}`"> {{ $t('onlineState.since') }} {{ $d($moment(device.lastConnect).toDate(), 'datetime-short')}}  </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    onlineState() {
      return this.device.online ? "success" : "danger";
    },
  },
};
</script>

<style scoped>
</style>