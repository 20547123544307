<template>
  <b-row>
    <b-col v-for="row in viewData.dataRows" :key="`viewData-${row.name}`">
      <h4>{{row.name}}</h4>
      <b-list-group>
        <legacy-info-element v-for="dataRow in row.data" :key="dataRow.dname" :dataRow="dataRow"/>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import LegacyInfoElement from './LegacyInfoElement.vue';
import {generateViewData} from './legacyViewData';

export default {
  components: {
    LegacyInfoElement,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    viewData() {
      return generateViewData(this.device)
    }
  },
};
</script>

<style lang="scss" scoped>
</style>