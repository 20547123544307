<template>
  <b-dropdown right text="Right align">
    <template #button-content>
      <i class="fas fa-ellipsis-h"></i>
    </template>
    <!-- Device Properties -->
    <b-dropdown-item
      :to="{
        name: 'DeviceProperties',
        params: { deviceid: device.id },
      }"
      ><i class="fas fa-cogs mr-2"></i>{{ $t("device-select.openProperties") }}
    </b-dropdown-item>

    <!-- Portainer -->
    <b-dropdown-item v-if="!device.legacy" @click="openPortainer(device)"
      ><i class="fab fa-docker mr-2"></i>{{ $t("device-select.openPortainer") }}
    </b-dropdown-item>

    <!-- Update -->
    <b-dropdown-item v-if="!device.legacy" @click="updateDevice(device)"
      ><i class="fas fa-cloud-download-alt mr-2"></i
      >{{ $t("device-select.update") }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { updateDevice } from "@/util/deviceUtils";

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("users", ["showDeviceProperties", "getToken"]),
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),

    openPortainer(device) {
      let url =
        `https://remote.eospowersolutions.com?action=login&jwt=${this.getToken}&onsuccess=https://${device.id}.remote.eospowersolutions.com` +
        (device.httpPath || "/") +
        `portainer/`;

      window.open(url, "_blank");
    },
    updateDevice(device) {
      updateDevice(device)
        .then(() => {
          this.fireAlert(
            [this.$t("Success"),
            this.$t("device-select.update-initiated"),
            "success"]
          );
        })
        .catch(() => {
          this.fireAlert(
            [this.$t("Error"),
            this.$t("operationFailed"),
            "danger"]
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>