<template>
  <b-container>
    <h1>{{ $t("device-select.select") }}</h1>

    <section class="sticky-menu p-2 d-flex flex-column">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          mb-3
          flex-column flex-md-row
        "
      >
        <!-- Searchbox -->
        <div class="d-flex justify-content-start align-items-center flex-fill">
          <i class="fas fa-search mr-2"></i>
          <b-form-input
            :placeholder="$t('users.search')"
            v-model="searchString"
            :value="getSearchString"
            autofocus
            class="flex-fill"
            autocomplete="off"
            @input="updateSearchString"
          ></b-form-input>
        </div>
        <div class="mt-md-0 mt-2">
          <!-- View Switcher -->
          <b-button-group class="ml-2">
            <b-button
              @click="changeViewMode('list')"
              v-b-tooltip.hover
              :title="$t('device-select.listView')"
              ><i class="fas fa-bars"></i
            ></b-button>
            <b-button
              @click="changeViewMode('block')"
              v-b-tooltip.hover
              :title="$t('device-select.blockView')"
              ><i class="fas fa-th-large"></i
            ></b-button>
          </b-button-group>

          <!-- Toggle Menu (only available in listview) -->
          <b-button
            v-if="viewMode === 'list' && showDeviceProperties"
            class="ml-2"
            v-b-toggle.device-button-menu
            @click="toggleSelectionMode"
            ><i class="fas fa-layer-group"></i
          ></b-button>
        </div>
      </div>

      <b-collapse 
        id="device-button-menu"
        v-if="showDeviceProperties"
      >
        <b-card
          class="mt-2"
          :title="$t('device-select.batch-menu')"
          :sub-title="
            $tc('device-select.devices-selected', selectedDevices.length)
          "
        >
          <div class="d-flex align-items-center justify-content-around flex-column flex-md-row">
            <b-button
              class="mx-1 my-md-0 my-2"
              @click="updateDevices(selectedDevices)"
              :disabled="selectedDevices.length === 0"
              ><i class="fas fa-sync-alt"></i>
              {{ $t("device-select.update") }}</b-button
            >

            <div class="d-flex align-items-center my-md-0 my-2">
              <b-form-group
                :label="$t(`properties.names.bulk-update_configuration`)"
                label-for="update_configuration"
                :invalid-feedback="$t('properties.updateConfigInvalid')"
                :state="updateConfigState"
              >
                <b-form-select
                  v-model="bulkUpdateConfiguration"
                  :options="updateConfigOptions"
                  id="update_configuration"
                  :state="!updateConfigState ? false : null"
                  :disabled="selectedDevices.length === 0"
                >
                  <!-- This slot appears above the options from 'options' prop -->
                  <template #first>
                    <b-form-select-option :value="undefined">
                      --
                      {{ $t("properties.selectOptionBulk") }}
                      --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <b-button
                variant="primary"
                @click="
                  setUpdateConfigurations(
                    selectedDevices,
                    bulkUpdateConfiguration
                  )
                "
                class="mt-3"
                :disabled="bulkUpdateConfiguration === undefined"
              >
                <i class="fas fa-check"></i>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </section>

    <!-- List View -->
    <device-list-view
      v-if="viewMode === 'list' && getViewDevices"
      :devices="getViewDevices"
      :selectionMode="selectionMode"
      :selectedDevices.sync="selectedDevices"
    />

    <!-- Block View -->
    <device-block-view v-if="viewMode === 'block' && getViewDevices" :devices="getViewDevices" />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeviceListView from "@/components/DeviceListView.vue";
import DeviceBlockView from "../components/DeviceBlockView.vue";
import { updateDevice, setUpdateConfiguration } from "../util/deviceUtils";
import axios from "axios";

export default {
  components: { DeviceListView, DeviceBlockView },
  computed: {
    ...mapGetters("devices", [
      "getRawDevices",
      "getViewDevices",
      "getSearchString",
    ]),
    ...mapGetters("users", ["showDeviceProperties"]),

    updateConfigState() {
      return [...this.updateConfigOptions, undefined].includes(
        this.bulkUpdateConfiguration
      );
    },
  },
  data() {
    return {
      viewMode: "list",
      searchString: "",
      selectionMode: false,
      selectedDevices: [],
      updateConfigOptions: [],
      bulkUpdateConfiguration: undefined,
      timerId: null,
    };
  },
  methods: {
    ...mapActions("devices", [
      "loadDevices",
      // "loadGroups",
      "changeSearchstring",
    ]),
    ...mapActions("alerts", ["fireAlert"]),

    getData() {
      this.loadDevices();
    },

    openDevice(device) {
      this.$router.push({ name: "Device", params: { device: device } });
      // console.log("SELECTED: ", device);
    },

    // eslint-disable-next-line no-undef
    updateSearchString: _.debounce(function () {
      this.changeSearchstring(this.searchString);
    }, 100),

    changeViewMode(mode) {
      this.viewMode = mode;
      localStorage.setItem("viewMode", mode);
    },

    updateDevices(devices) {
      devices.forEach((device) => {
        updateDevice(device);
      });
      this.fireAlert([
        this.$t("Success"),
        this.$t("device-select.update-initiated"),
        "success",
      ]);
    },

    setUpdateConfigurations(selectedDevices, configurationName) {
      selectedDevices.forEach((device) => {
        setUpdateConfiguration(device, configurationName);
      });
      this.fireAlert([
        this.$t("Success"),
        this.$t("device-select.bulk-config-set", { name: configurationName }),
        "success",
      ]);
    },

    toggleSelectionMode() {
      this.selectionMode = !this.selectionMode;

      if (!this.selectionMode) {
        this.selectedDevices = [];
      }
    },
  },
  mounted() {
    this.viewMode = localStorage.getItem("viewMode") || "list";
    this.searchString = this.getSearchString;
    axios
      .get(`/ui-api/v1/device-configs`)
      .then((res) => {
        this.updateConfigOptions = res.data;
      })
      .catch((err) => {
        console.error(err);
        this.updateConfigOptions = [];
      });
    this.getData();
    this.timerId = setInterval(this.getData, 1000);
  },

  destroyed() {
    clearInterval(this.timerId);
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.sticky-menu {
  position: sticky;
  top: $navbar-height;
  background: white;
  z-index: 100;
}
</style>
