const moment = require('moment');

export function generateViewData(device) {
  var viewDevice = {
    name: device.name,
    description: device.description,
    id: device.id,
    httpPath: device.httpPath,
    group: device.group,
    type: 'device',
    dataRows: [{
      name: "Network",
      data: [{
        dname: "Status",
        dvalue: device.online ? 'online' : 'offline',
        variant: device.online ? 'success' : 'danger',
        badge: true,
      },
      {
        dname: "Last update",
        dvalue: moment(device.timestamp).format('DD') != moment().format('DD') ? moment(device.timestamp).format('DD.MM.YYYY HH:mm') : moment(device.timestamp).format('HH:mm'),
        variant: '',
        badge: true,
      },
      ]
    },
    {
      name: "Energy Flow Controller",
      data: [{
        dname: "Supervisor Service State",
        dvalue: device.es,
        variant: device.es_variant,
        badge: true,
      },
      {
        dname: "Energy Flow Controller",
        dvalue: device.ef,
        variant: device.ef_variant,
        badge: true,
      },
      {
        dname: "Energy Flow Controller Alpha",
        dvalue: device.efa,
        variant: device.efa_variant,
        badge: true,
      },
      {
        dname: "Inverter state",
        dvalue: device.inverterstate,
        variant: device.inverterstate_variant,
        badge: true,
      },
      ]
    },
    {
      name: "Memory Overview",
      data: [{
        dname: "available memory [MB]",
        dvalue: device.availableMemory,
        variant: device.availableMemory_variant,
        badge: true,
      },
      {
        dname: "available EMMC space [MB]",
        dvalue: device.availableEmmcSpace,
        variant: device.availableEmmcSpace_variant,
        badge: true,
      },
      {
        dname: "available SD Card space [MB]",
        dvalue: device.availableSdCardSpace,
        variant: device.availableSdCardSpace_variant,
        badge: true,
      },

      ]
    },
    {
      name: "Developer Information",
      data: [{
        dname: "App uptime [days]",
        dvalue: device.applicationUptime | 'round',
        variant: device.applicationUptime_variant,
        badge: true,
      },
      {
        dname: "OS uptime [days]",
        dvalue: moment().diff(device.uptime, 'days'),
        variant: '',
        badge: true,
      },
      {
        dname: "flavour",
        dvalue: device.flavour,
        variant: '',
        badge: true,
      },
      {
        dname: "em version",
        dvalue: device.emVersion,
        variant: '',
        badge: true,
      },
      {
        dname: "build number",
        dvalue: device.buildNumber,
        variant: '',
        badge: true,
      },
      {
        dname: "macchinaIO version",
        dvalue: device.macchinaIoVersion,
        variant: '',
        badge: true,
      },
      {
        dname: "id",
        dvalue: device.id,
        variant: '',
        badge: true,
      },
      ]
    },
    ],
  };

  return viewDevice;
}