<template>
  <b-container>
    <div v-for="device in devices" :key="device.id">
      <group-list-item
        v-if="device.type === 'group'"
        :group="device"
        :selectionMode="selectionMode"
        @changedSelection="changedSelection"
      />
      <device-list-item
        v-else
        :device="device"
        :selectionMode="selectionMode"
        @changedSelection="changedSelection"
      />
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeviceListItem from "@/components/device/DeviceListItem.vue";
import GroupListItem from "@/components/device/GroupListItem.vue";

import { updateState, deleteProperty } from "@/util/deviceUtils";

export default {
  components: {
    DeviceListItem,
    GroupListItem,
  },
  props: {
    devices: {
      type: Array,
      required: true,
    },
    selectionMode: {
      type: Boolean,
      default: false,
    },
    selectedDevices: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("users", ["showDeviceProperties"]),
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    changedSelection(checked, device) {
      if (checked) {
        // add to selected list
        this.selectedDevices.push(device);
      } else {
        // remove from selection
        this.selectedDevices.splice(this.selectedDevices.indexOf(device), 1);
      }
      this.$emit("update:selection", this.selection);
    },
    updateState,
    deleteUpdateState(device) {
      deleteProperty(device, "update_status")
        .then(() => {
          delete device.update_status;
        })
        .catch((err) => {
          this.fireAlert([
            this.$t("Error"),
            this.$t("operationFailed"),
            "danger",
          ]);
          console.error(err.response);
        });
    },
    getUpdateStatusProperty(device, propname) {
      if (!device.update_status) {
        return "";
      }
      let o = JSON.parse(device.update_status);
      return o[propname];
    },
  },
};
</script>

<style scoped>
.subtitle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: light !important;
}

.disabled {
  display: none;
}
</style>