import axios from 'axios'
import moment from 'moment'

export function
  changeProperty(device, propname, value) {
  return axios
    .put(`/my-devices/api/device/${device.id}`, {
      [propname]: value,
    })
}

export function
  deleteProperty(device, propname) {
  return axios
    .delete(
      `/my-devices/api/device/${device.id
      }/${encodeURI(propname)}`
    )
}
export function
  updateDevice(device) {
  return changeProperty(device, 'update_requested', moment().unix())
}

export function
  setUpdateConfiguration(device, configurationName) {
  return changeProperty(device, 'update_configuration', configurationName)
}

export function
  updateState(device) {
  let state = device.update_status;
  let requested = device.update_requested; // contains the unix timestamp of request

  if (!requested && !state) {
    return null;
  }

  if (!state) {
    return "pending";
  }

  state = JSON.parse(state);

  if (!state.timestamp) {
    return "unknown";
  }

  let requestedTime = moment.unix(requested);
  let stateTime = moment.unix(state.timestamp);

  if (requestedTime > stateTime) {
    return "pending";
  } else {
    // update is eighter in progress or finished.

    // hide updatestate if it is older than 10 minutes.
    if (stateTime < moment().subtract(10, "minutes")) {
      return null;
    }

    if (['done', 'failed', 'inprogress'].includes(state.status)) {
      return state.status;
    }
  }
  return "unknown";
}