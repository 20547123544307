<template>
  <div v-if="state != null" class="update-state">
    <span class="mr-1">Update: </span>
    <i
      v-if="state == 'pending'"
      class="far fa-hourglass ml-2"
      v-b-tooltip.hover
      :title="$t('update.pending')"
    ></i>
    <b-spinner
      v-if="state === 'inprogress'"
      variant="primary"
      small
      v-b-tooltip.hover
      :title="$t('update.inprogress')"
    ></b-spinner>
    <i
      v-if="state == 'done'"
      class="fas fa-check-circle ml-2 text-success"
      v-b-tooltip.hover
      :title="$t('update.done')"
    ></i>
    <i
      v-if="state == 'failed'"
      class="fas fa-times ml-2 text-danger"
      v-b-tooltip.hover
      :title="$t('update.failed')"
    ></i>
    <i
      v-if="state == 'unknown'"
      class="fas fa-question-circle ml-2 text-warning"
      v-b-tooltip.hover
      :title="$t('update.unknown')"
    ></i>
  </div>
</template>

<script>
import {updateState} from '@/util/deviceUtils';

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    state() {
      return updateState(this.device);
    },
  },
};
</script>

<style scoped>
.update-state {
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
</style>