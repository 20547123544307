<template>
  <b-container>
    <div class="my-grid">
      <b-card
        v-for="device in devices"
        :key="device.id"
        img-src="../assets/solar.png"
        class="hoverable"
      >
        <online-state :device="device" />

        <b-card-title>{{ device.name }}</b-card-title>
        <b-card-sub-title>{{ device.description }}</b-card-sub-title>
        <template #footer>
          <div class="d-flex justify-content-around align-items-center">
            <device-state :device="device" />
            <b-button @click="openDevice(device)" variant="primary">{{
              $t("device-select.openMonitor")
            }}</b-button>

            <device-context-menu v-if="showDeviceProperties" :device="device" />
          </div>
        </template>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import DeviceState from "./DeviceState.vue";
import OnlineState from "./OnlineState.vue";
import DeviceContextMenu from '@/components/DeviceContextMenu.vue';

export default {
  components: { DeviceState, OnlineState, DeviceContextMenu },
  props: {
    devices: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("users", ["showDeviceProperties"]),
  },
};
</script>

<style scoped>
.my-grid {
  display: grid;
  justify-items: center;
  /* 280px is the minimum a column can get, you might need to adjust it based on your needs. */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1.5rem;
}

.my-grid > * {
  width: 100%;
  max-width: 20rem;
}

.card-img {
  padding: 0.5rem;
}

.hoverable:hover {
  background-color: #e4e4e4;
  /* transform: scale(1.1); */ /* ... breaks dropdown menu */
  transition: 0.1s ease-in-out;
}
</style>