<template>
  <b-list-group-item class="item">
    {{ dataRow.dname }}:
    <b-badge :variant="dataRow.variant" pill>
      {{ dataRow.dvalue }}
    </b-badge>
  </b-list-group-item>
</template>

<script>
export default {
  props: {
    dataRow: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>