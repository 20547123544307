<template>
  <b-card :class="{ disabled: device.legacy && selectionMode }">
    <div class="d-flex justify-content-between align-itmes-center">
      <div
        class="
          d-flex
          flex-md-row flex-column
          align-items-start align-items-md-center
        "
      >
        <b-form-checkbox
          v-if="selectionMode"
          :disabled="device.legacy"
          size="lg"
          @change="$emit('changedSelection', $event, device)"
        />

        <online-state :device="device" class="mr-2" />
        <router-link
          :to="{ name: 'Device', params: { device } }"
          class="text-reset"
        >
          <b-card-title
            class="mb-0 d-flex align-items-baseline flex-md-row flex-column"
          >
            <span>{{ device.name }}</span>
            <small
              v-if="device.description"
              class="text-muted subtitle mx-md-1"
              >{{ device.description }}</small
            >
          </b-card-title>
        </router-link>
      </div>
      <div class="d-flex align-items-end flex-md-row flex-column">
        <update-state :device="device" class="mx-md-2" />
        <device-state :device="device" />
        <b-button-group v-if="showDeviceProperties">
          <b-button
            v-b-tooltip.hover
            :title="$t('device-select.showInfo')"
            v-b-toggle="device.id"
            ><i class="fas fa-info"></i
          ></b-button>

          <device-context-menu :device="device" />
        </b-button-group>
      </div>
    </div>
    <b-collapse :id="device.id">
      <b-card-body>
        <b-card-text v-if="device.legacy">
          <h3 class="text-center mb-4">
            <i class="fas fa-code"></i> {{ $t("legacy-device") }}!
          </h3>
          <legacy-device-info :device="device" />
        </b-card-text>
        <b-card-text v-else> ID: {{ device.id }} </b-card-text>

        <b-card v-if="device.update_status" :title="$t('update.Update')">
          <b-list-group>
            <b-list-group-item
              ><strong>{{ $t("update.Status") }}: </strong
              >{{
                getUpdateStatusProperty(device, "status")
              }}</b-list-group-item
            >
            <b-list-group-item
              ><strong>{{ $t("update.Timestamp") }}: </strong>
              {{
                $moment
                  .unix(getUpdateStatusProperty(device, "timestamp"))
                  .format("DD.MM.YYYY hh:mm")
              }}
            </b-list-group-item>
            <b-list-group-item
              ><strong>{{ $t("update.Configuration") }}: </strong
              >{{
                getUpdateStatusProperty(device, "configuration")
              }}</b-list-group-item
            >
            <b-list-group-item
              ><strong>{{ $t("update.Message") }}: </strong
              >{{ getUpdateStatusProperty(device, "msg") }}</b-list-group-item
            >
          </b-list-group>
          <div class="d-flex justify-content-end align-items-center mt-2">
            <b-button variant="primary" @click="deleteUpdateState(device)"
              ><i class="fas fa-clipboard-check"></i>
              {{ $t("device-select.confirm-clear") }}</b-button
            >
          </div>
        </b-card>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { updateState, deleteProperty } from "@/util/deviceUtils";
import DeviceState from "@/components/DeviceState.vue";
import DeviceContextMenu from "@/components/DeviceContextMenu.vue";
import UpdateState from "@/components/UpdateState.vue";
import OnlineState from "@/components/OnlineState.vue";
import LegacyDeviceInfo from '@/components/legacy/LegacyDeviceInfo.vue';
export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
    selectionMode: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    DeviceState,
    OnlineState,
    DeviceContextMenu,
    UpdateState,
    LegacyDeviceInfo,
  },

  computed: {
    ...mapGetters("users", ["showDeviceProperties"]),
  },

  methods: {
    updateState,
    deleteUpdateState(device) {
      deleteProperty(device, "update_status")
        .then(() => {
          delete device.update_status;
        })
        .catch((err) => {
          this.fireAlert([
            this.$t("Error"),
            this.$t("operationFailed"),
            "danger",
          ]);
          console.error(err.response);
        });
    },
    getUpdateStatusProperty(device, propname) {
      if (!device.update_status) {
        return "";
      }
      let o = JSON.parse(device.update_status);
      return o[propname];
    },
  },
};
</script>

<style>
</style>