<template>
  <div v-if="showDeviceState" class="device-state mr-2">
    <i v-if="state==='OK'" v-b-tooltip.hover :title="$t('state.ok')" class="far fa-check-circle text-success"></i>
    <i v-if="state==='WARNING'" v-b-tooltip.hover :title="$t('state.warning')" class="fas fa-exclamation-triangle text-warning"></i>
    <i v-if="state==='ERROR'" v-b-tooltip.hover :title="$t('state.error')" class="fas fa-exclamation-circle text-danger"></i>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("users", ["showDeviceState"]),
    state() {
      let newState = this.device.state;
      if (!newState) {
        // legacy devices
        let variant = this.device.variant;
        if (!variant) {
          return "unknown";
        }
        newState =
          variant == "success"
            ? "OK"
            : variant == "warning"
            ? "WARNING"
            : variant == "danger"
            ? "ERROR"
            : "unknown";
      }
      return newState;
    },
  },
};
</script>

<style scoped>
.device-state {
  font-size: 1.25rem;
}
</style>