<template>
  <b-card>
    <b-card-title v-b-toggle="group.id" class="d-flex justify-content-between align-items-center">
      <div>
        <i class="fas fa-object-group" />
        {{ group.name }}
      </div>

      <b-badge variant="primary" pill
        >{{ $tc("group-list-item.nDevices", group.devices.length) }}
      </b-badge>
    </b-card-title>
    <b-collapse :id="group.id" class="mt-2">
      <device-list-item
        v-for="device in group.devices"
        :key="device.id"
        :device="device"
        :selectionMode="selectionMode"
        @changedSelection="$emit('changedSelection', $event, device)"
      />
    </b-collapse>
  </b-card>
</template>

<script>
import DeviceListItem from "./DeviceListItem.vue";
export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
    selectionMode: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DeviceListItem,
  },
};
</script>

<style scoped>
.card-title {
  margin-bottom: 0;
}
</style>